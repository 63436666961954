import {Module, VuexModule, Mutation, Action} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import config from "@/core/config/APIConfig";
import axios from "axios";
import store from "@/store";

interface PartnersTableItem {
    id: string | number,
    name: string,
    email: string,
    phone: string,
    group: string,
    courseName: string,
    refCode: string,
    refName: string
}

interface PartnersModuleInterface {
    table: PartnersTableItem[],
    tableErrors: string
}

@Module
export default class PartnersModule extends VuexModule implements PartnersModuleInterface {
    table = []
    tableErrors = ''
    leadsList = []
    partnersListData = []

    get getPartnersTable(): PartnersTableItem[] {
        return this.table;
    }

    get getPartnersError(): string {
        return this.tableErrors;
    }

    get getterLeadsList(): any {
        return this.leadsList ? this.leadsList : [];
    }

    get getterPartnersList(): any {
        return this.partnersListData ? this.partnersListData : []
    }

    @Mutation
    [Mutations.SET_PARENT_TABLE](table) {
        this.table = table;
    }

    @Mutation
    [Mutations.SET_PARENT_TABLE_ERROR](error) {
        this.tableErrors = error;
    }

    @Mutation
    [Mutations.SET_LEADS_LIST](data) {
        this.leadsList = data
    }

    @Mutation
    [Mutations.SET_PARTNERS_LIST](data) {
        this.partnersListData = data
    }

    @Action
    [Actions.UPLOAD_FILES](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post('/tableprtners', credentials)
                .then((res) => {
                    this.context.commit(Mutations.SET_PARENT_TABLE, res.data);
                    resolve();
                })
                .catch(({response}) => {
                    this.context.commit(Mutations.SET_PARENT_TABLE_ERROR, response.data.message[0]);
                    reject();
                });
        });
    }

    /* Add new partner */
    @Action
    [Actions.ADD_NEW_PARTNER](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(config.routeAddNewPartner, credentials)
                .then((res) => {
                    console.log('ADD_NEW_PARTNER - SUCCESS!')
                    resolve();
                })
                .catch(({response}) => {
                    console.log('ADD_NEW_PARTNER - ERROR!')
                    reject();
                });
        });
    }

    /*  Analyze csv file  */
    @Action
    [Actions.ANALYZE_CSV](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post(config.routeAnalyzeCSV, {
                data: credentials,
            })
                .then((res) => {
                    console.log('ANALYZE_CSV - SUCCESS!')
                    resolve();
                })
                .catch(({response}) => {
                    console.log('ANALYZE_CSV - ERROR!')
                    reject();
                });
        });
    }

    /**
     * Get all partners list
     * @returns
     */
    @Action
    [Actions.GET_PARTNERS_LIST]() {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(config.routeGetPartnersList)
                .then((res) => {
                    console.log('GET_PARTNERS_LIST - SUCCESS!')
                    console.log(res)
                    this.context.commit(Mutations.SET_PARTNERS_LIST, res.data)
                    resolve();
                })
                .catch(({response}) => {
                    console.log('GET_PARTNERS_LIST - ERROR!')
                    this.context.commit(Mutations.SET_PARTNERS_LIST, null)
                    reject();
                });
        });
    }

    /**
     * Get leads list by admin or manager role
     * @params partnerID
     * @returns
     */
    @Action
    [Actions.GET_LEADS_LIST](partnerID) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(`${config.routeGetLeadsList}${partnerID}`)
                .then((res) => {
                    console.log('GET_LEADS_LIST - SUCCESS!')
                    console.log(res)
                    this.context.commit(Mutations.SET_LEADS_LIST, res.data)
                    resolve();
                })
                .catch(({response}) => {
                    console.log('GET_LEADS_LIST - ERROR!')
                    this.context.commit(Mutations.SET_LEADS_LIST, null)
                    reject();
                });
        });
    }

    /**
     * Get leads list by partner role
     * @returns
     */
    @Action
    [Actions.GET_LEADS_LIST_BY_PARTNER]() {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get(config.routeGetLeadsListByPartner)
                .then((res) => {
                    console.log('GET_LEADS_LIST_BY_PARTNER - SUCCESS!', res.data)
                    this.context.commit(Mutations.SET_LEADS_LIST, res.data)
                    resolve();
                })
                .catch(({response}) => {
                    console.log('GET_LEADS_LIST_BY_PARTNER - ERROR!')
                    this.context.commit(Mutations.SET_LEADS_LIST, null)
                    reject();
                });
        });
    }

    /**
     * Delete partner by ID
     * @params partnerID
     * @returns
     */
    @Action
    [Actions.DELETE_PARTNER](partnerID) {
        return new Promise<void>((resolve, reject) => {
            ApiService.setHeader();
            ApiService.delete(`${config.routeDeletePartnerByID}${partnerID}`)
                .then((res) => {
                    console.log('DELETE_PARTNER - SUCCESS!')
                    console.log(res)
                    resolve();
                })
                .catch(({response}) => {
                    console.log('DELETE_PARTNER - ERROR!')
                    reject();
                });
        });
    }
}