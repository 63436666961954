import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import PartnersModule from "@/store/modules/PartnersModule";
import UsersModule from "@/store/modules/UsersModule";
import SubscribersModule from "@/store/modules/SubscribersModule";
import SettingsModule from "@/store/modules/SettingsModule";
import CursesModule from "@/store/modules/CurseModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    UsersModule,
    PartnersModule,
    SubscribersModule,
    SettingsModule,
    CursesModule
  },
});

export default store;
