import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import config from "@/core/config/APIConfig";
import { array } from "yup";
const USERS_STORE = "users_store" as string;
const USER_DETAILS_STORE = "user_details_store" as string;

export interface User {
  _id: string;
  fname: string;
  lname: string;
  email: string;
  phone: string;
  notes: string;
  password: string;
  role: string;
  createdAt: string;
  updatedAt: string;
  orders: [];
  subscriptions: [];
}

export interface UsersInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class UsersModule extends VuexModule implements UsersInfo {
  errors = []
  user = {} as User
  isAuthenticated = !!JwtService.getToken()
  userList = []

  @Mutation
  [Mutations.SET_USER_STORE](data) {
    this.user = data
  }

  @Mutation
  [Mutations.SET_USERS](data) {
    this.userList = data
  }

  @Action
  [Actions.FETCH_USERS]() {
    if (JwtService.getToken()) {
      return new Promise<void>((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(config.routeGetUsers, {})
          .then((response) => {
            if (undefined !== response.data) {
              this.context.commit(Mutations.SET_USERS, response.data);
            }
            resolve();
          })
          .catch(({ response }) => {
            console.log(response.data.message);
            reject();
          });
      });
    }
  }

  @Action
  [Actions.FETCH_USER](params) {
    if (JwtService.getToken()) {
      return new Promise<void>((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(config.routeGetUser, params)
          .then((response) => {
            if (undefined !== response.data) {
              console.log("fetch_user", response.data);
              this.context.commit(Mutations.SET_USER_STORE, response.data);
            }
            resolve();
          })
          .catch(({ response }) => {
            console.log(response.data.message);
            reject();
          });
      });
    }
  }

  get getUsersList() {
    return this.userList ? this.userList : []
  }

  get userDetails() {
    return this.user
  }

}
