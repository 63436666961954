import { createI18n } from "vue-i18n";

const messages = {
  en: {
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error: "Error",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create an Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    yookassa: "Yookassa",
    сourses: "Courses",
    months: "Months",
    days: "Days",
    paymentone: "Payment one",
    update: "Update",
    addCustomer: "Add a user",

    selectCountry: "Select a Country...",
    pleaseWait: "Please wait...",
    submit: "Submit",
    discard: "Discard",
    reset: "Reset",
    country: "Country",
    postCode: "Post Code",
    state: "State",
    city: "City",
    addressLine2: "Address Line 2",
    addressLine1: "Address Line 1",
    additional: "Additional",
    description: "Description",
    email: "E-mail",
    name: "Name",

    signin: "Sign In",
    newHere: "New Here?",
    password: "Password",
    forgotPassword: "Forgot Password?",
    continue: "Continue",
    goToPayment: "Go to payment",
    firstName: "First Name",
    lastName: "last Name",
    phoneNumber: "Phone",
    back: "Back",
    myInvoices: "My Invoices",
    invoice: "Invoice",
    date: "Date",
    status: "Status",
    lastUsed: "Last used",
    telegramID: "Telegram ID",
    nickname: "Nickname",
    amount: "Amount",
    paymentId: "Payment Id",
    download: "Download",
    addSubscriptionByUser: "Buy Course Subscription",
    subscriptions: "Subscriptions",
    overview: "Overview",
    profileDetails: "Profile Details",
    editProfile: "Edit Profile",
    fullName: "Full Name",
    buyCourse: "Buy Course",
    todaySubscriptions: "Today Subscriptions",
    actions: "Actions",
    refId: "Ref ID",
    group: "Group",
    subscriber: "Subscriber",
    view: "View",
    debtors: "Debtors",
    expired: "Expired",
    avatar: "Avatar",
    regLink: "Registration link",
    allowedFile: "Allowed file types: png, jpg, jpeg.",
    saveChanges: "Save Changes",
    deactivateAccount: "Deactivate Account",
    home: "Home",
    mySubscriptions: "My Subscriptions",
    unsubscribe: "Unsubscribe",
    subscriptionsName: "Subscriptions name",
    cost: "Cost",
    paymentMethods: "Payment Methods",
    delete: "Delete",
    addUser: "Add user",
    role: "Role",
    onlyDebtors: "Only debtors",
    countOrders: "Count",
    earnings: "Earnings",
    subscriptionFor: "Subscription for",

    dateCreated: "Date created",
    nextPayment: "Next payment",
    partnerReferral: "Partner referral",
    paymentCost: "Payment cost",
    accountId: "Account ID",
    edit: "Edit",
    thisYear: "This year",
    subscriptionDetails: "Subscription Details",
    subscription: "Subscription",
    revenue: "Revenue",
    add: "Add",
    pause: "Pause",
    editData: "Edit Data",
    selectStatus: "Select status...",
    statusActive: "Active",
    statusPending: "Pending",
    statusCancelled: "Cancelled",
    statusDebit: "Debit",
    statusLocked: "Pause",
    cancel: "Cancel",
    active: "Active",
    debtor: "Debtor",
    locked: "Pause",
    canceled: "Canceled",
    cancelled: "Canceled",
    nameCourse: "Course name",
    users: "Users",
    userName: "User Name",
    questionPause: "Are you sure?",
    alertPause: "This subscription will be put to pause.",
    yesPause: "Yes, please",
    noPause: "Cancel",
    successPause: "Paused",
    pauseStart: "Start",
    alertPauseOff: "This subscription will be restore.",
    successPauseOff: "This subscription was restored",
    tryAgain: "Try again!",
    forgotPasswordText: "Enter your email to reset your password.",

    unsubscribeFor: "Unsubscribe for",
    unsubscribeForText: " ",
    updateSubscription: "Update subscription",
    updateSubscriptionText: " ",
    extend: "Extend",
    unsubscribeForSuccess: "Unsubscribed!",
    good: "Ok, got it!",
    processDone: "All is cool! Now you submit this form",
    forgotPasswordTextAlert: "All is cool! Now you submit this form",
    newPartner: "New Partner",
    updateStatistic: "Update Statistic",
    addNewPartner: "Add new partner",
    partnersStatistic: "Update leads",
    partnersCSVChoose: "Choose CSV file for checking:",
    partnersCheck: "Check",
    partnersNotFound: "Leads not found",
    subscriptionForCourse: "for",
    summa: "Total",
    leads: "Leads",
    dateReg: "Date created",
    source: "Source",
    noLeads: "Leads not found",
    leadsChoosePartner: "Choose a pertner",
    overdue: "Overdue",
    notpaid: "Not paid",
    paid: "Paid",
    notes: "Notes",
    homeUsersDescription: " ",
    homeDebtorsDescription: " ",
    expires: "Expires",
    onlyDigits: "Must be only digits",
    least11: "Phone must be at least 11 characters",
    most11: "Phone must be at most 11 characters",
    isRequired: "It is a required field",
    passwordLeast4: "Password must be at least 4 characters",
    emailValid: "Email must be a valid email",
    deactivated: "Deactivated",
    disabled: "Disabled",
  },
  ru: {
    dashboard: "Панель управления",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Страницы",
    profile: "Профиль",
    profileOverview: "Обзор",
    projects: "Проекты",
    campaigns: "Кампании",
    documents: "Документы",
    connections: "Коннекты",
    wizards: "Установщик",
    horizontal: "Горизонтальный",
    vertical: "Вертикальный",
    account: "Аккаунт",
    accountOverview: "Обзор",
    settings: "Настройки",
    authentication: "Авторизация",
    basicFlow: "Basic Flow",
    signIn: "Вход",
    signUp: "Регистрация",
    passwordReset: "Сбросить пароль",
    error: "Ошибка",
    error404: "Ошибка 404",
    error500: "Ошибка 500",
    apps: "Apps",
    chat: "Чат",
    privateChat: "Приватный чат",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Виджет",
    widgetsLists: "Список",
    widgetsStatistics: "Статистика",
    widgetsCharts: "График",
    widgetsMixed: "Mixed",
    widgetsTables: "Таблица",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "Главный",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Создать аккаунт",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    yookassa: "Yookassa",
    сourses: "Курсы",
    months: "Месяцы",
    days: "Дни",
    paymentone: "Один платеж",
    update: "Обновить",
    addCustomer: "Добавить нового пользователя",
    city: "Город",
    country: "Страна",
    name: "Название",
    description: "Описание",
    additional: "Дополнительно",
    addressLine1: "Адрес 1",
    addressLine2: "Адрес 2",
    state: "Область",
    postCode: "Индекс",
    discard: "Отмена",
    reset: "Сбросить",
    submit: "Отправить",
    selectCountry: "Выбрать страну",

    signin: "Войти",
    newHere: "Нет аккаунта?",
    password: "Пароль",
    forgotPassword: "Забыли пароль?",
    continue: "Продолжить",
    goToPayment: "Перейти к оплате",
    firstName: "Имя",
    lastName: "Фамилия",
    phoneNumber: "Номер телефона",
    back: "Назад",
    myInvoices: "История оплат",
    invoice: "Счет",
    date: "Дата",
    status: "Статус",
    amount: "Стоимость (₽)",
    paymentId: "Id оплаты",
    download: "Скачать",
    addSubscriptionByUser: "Купить подписку на курс",
    subscriptions: "Подписки",
    overview: "Обзор",
    profileDetails: "Профиль",
    editProfile: "Изменить профиль",
    fullName: "Полное имя",
    buyCourse: "Подписаться на курс",
    todaySubscriptions: "Сегодня на оплату",
    actions: "Действия",
    refId: "Ref ID",
    group: "Группа",
    subscriber: "Подписчик",
    view: "Перейти",
    debtors: "Должники",
    expired: "Истечет",
    avatar: "Фотография",
    regLink: "Ссылка на регистрацию",
    allowedFile: "Поддерживаемые типы: png, jpg, jpeg.",
    saveChanges: "Сохранить",
    deactivateAccount: "Остановить аккаунт",
    home: "Главная",
    mySubscriptions: "Мои подписки",
    unsubscribe: "Отписаться",
    email: "E-mail",
    pleaseWait: "Загрузка...",
    subscriptionsName: "Название",
    cost: "Стоимость (₽)",
    paymentMethods: "Cпособы оплаты",
    delete: "Удалить",
    addUser: "Добавить пользователя",
    role: "Роль",
    onlyDebtors: "Должники",
    countOrders: "Оплат шт.",
    earnings: "Оплаты (₽)",
    subscriptionFor: "Подписка от",

    dateCreated: "Дата создания",
    nextPayment: "Следующая оплата",
    partnerReferral: "Партнер Ref #",
    paymentCost: "Стоимость подписки (₽)",
    accountId: "Account ID",
    edit: "Изменить",
    thisYear: "Этот год",
    subscriptionDetails: "Детали подписки",
    subscription: "Подписка",
    revenue: "Доход (₽)",
    add: "Добавить",
    pause: "Пауза",
    editData: "Изменить данные",
    selectStatus: "Выбрать статус...",
    statusActive: "Активный",
    statusPending: "В обработке",
    statusCancelled: "Отменен",
    statusDebit: "Должник",
    statusLocked: "На паузе",
    cancel: "Отмена",
    active: "Активный",
    debtor: "Долг",
    locked: "На паузе",
    canceled: "Отменен",
    cancelled: "Отменен",
    nameCourse: "Курс",
    users: "Пользователи",
    questionPause: "Вы уверены?",
    alertPause: "Эта подписка будет приостановлена (заморожена)",
    yesPause: "Приостановить",
    noPause: "Отмена",
    successPause: "Приостановлено",
    pauseStart: "Разморозить",
    alertPauseOff: "Эта подписка будет разморожена",
    successPauseOff: "Подписка разморожена",
    tryAgain: "Попробуйте еще раз",
    forgotPasswordText: "Укажите свой e-mail для сброса пароля",

    unsubscribeFor: "Отписаться от",
    unsubscribeForText: "Это действие полностью отключит выбранную подписку. Остаток оплаченных дней останется активным.",
    updateSubscription: "Продлить",
    updateSubscriptionText: "Это действие продлит выбранную подписку не дожидаясь автоплатеж.",
    extend: "Продлить",
    unsubscribeForSuccess: "Вы успешно отписались от курса",
    good: "Хорошо",
    processDone: "Действие успешно выполнено!",
    forgotPasswordTextAlert: "Проверьте указанный почтовый ящик. В течение 10-ти минут на него должно прийти письмо.",
    newPartner: "Добавить партнера",
    updateStatistic: "Обновить лиды",
    addNewPartner: "Добавить нового партнера",
    partnersStatistic: "Загрузить статистику по лидам",
    partnersCSVChoose: "Выберите CSV файл из Timepad:",
    partnersCheck: "Анализировать",
    partnersNotFound: "Лиды не найдены",
    subscriptionForCourse: "для курса",
    summa: "Сумма (₽)",
    leads: "Лиды",
    dateReg: "Дата регистрации",
    source: "Источник (канал)",
    noLeads: "Лиды не найдены",
    leadsChoosePartner: "Выберите партнера",
    overdue: "Просрочено",
    notpaid: "Нет оплаты",
    paid: "Оплачено",
    notes: "Заметки",
    homeUsersDescription: "Подписки, которые сегодня автоматически продлевают подписку",
    homeDebtorsDescription: "Подписки, которые имеют долг",
    expires: "До мес./год:",
    onlyDigits: "Оставьте только цифры, без знака +",
    least11: "Номер телефона должен содержать не менее 11 цифр, без знака +",
    most11: "Номер телефона должен содержать не более 11 символов, без знака +",
    isRequired: "Это обязательное поле",
    passwordLeast4: "Пароль должен состоять не менее чем из 4 символов",
    emailValid: "E-mail адрес должен быть действительным",
    deactivated: "Деактивирован",
    disabled: "Отключенный",
    userName: "Имя пользователя",
    telegramID: "Телеграм ID",
    nickname: "Псевдоним",
    lastUsed: "Последнее использование",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "ru",
  globalInjection: true,
  messages,
});

export default i18n;