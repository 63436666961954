import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import config from "@/core/config/APIConfig";

@Module
export default class CursesModule extends VuexModule {
  coursesData = []


  get getAllCurses(): any {
    let data = localStorage.getItem("allCurses");
    if (data) {
      data = JSON.parse(data);
      return data;
    }
    return [];
  }

  @Mutation
  [Mutations.SET_ALL_CURSES](data) {
    // this.coursesData = data
    localStorage.setItem("allCurses", JSON.stringify(data));
  }

  @Action
  [Actions.CURSES_GET_ALL]() {
    return new Promise<void>((resolve) => {
      ApiService.get(config.routeCursesGetAll)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_CURSES, data);
          resolve()
        })
        .catch(({ response }) => {
          console.log(response?.data?.errors);
        });
    })
  }

  @Action
  [Actions.COURSE_GET_ONE]() {
    return new Promise<void>((resolve) => {
      ApiService.get(config.routeCursesBase)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ONE_COURSE, data);
          resolve()
        })
        .catch(({ response }) => {
          console.log(response?.data?.errors);
        });
    })
  }

}
