enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  UPLOAD_FILES = "uploadFiles",
  GET_USERS = "getUsers",
  GET_USER = "getUser",
  FETCH_USERS = "fetchUsers",
  FETCH_USER = "fetchUser",
  GET_SUBSCRIBERS_TABLE = "getSubscribersTable",
  GET_SUBSCRIBERS_TODAY = "getSubscribersToday",
  GET_SUBSCRIBERS_DEBTORS = "getSubscribersDebtors",
  GET_SUBSCRIBERS_ONE = "getSubscribersOne",
  REMOVE_SUBSCRIBER_ONE = "removeSubscriberOne",
  REGISTER_SUB = "registerSub",
  LOGIN_SUB = "loginSub",
  SAVE_DATA_SUB = "saveDataSub",
  SAVE_DATA_SUB_OBJ = "saveDataSubObj",
  CLEAR_DATA_SUB = "clearDataSub",
  FORGOT_PASSWORD_SUB = "forgotPasswordSub",
  SET_SETTINGS = "setSettings",
  SET_COURSES = "setCourses",
  CLEAR_SETTING_ERROR = "clearSettingError",
  GET_SETTINGS = "getSettings",
  GET_COURSES = "getCourses",
  GET_SUBSCRIBER = "getSubscriber",
  CURSES_GET_ALL = "cursesGetAll",
  COURSE_GET_ONE = "courseGetOne",
  AUTH_SUB_REG = "authSubReg",
  GET_SUBSCRIBER_ARREARS = "getSubscriberArrears",
  SET_SUM_AMOUNT_ACT = "setSumAmountAct",
  AUTH_LOGOUT = "authLogout",
  USER_SET_SETTINGS = "userSetSettings",
  USER_GET_SETTINGS = "userGetSettings",
  EDIT_USER_DETAILS = "editUserDetails",
  EDIT_SUBSCRIPTION_DETAILS = "editSubscriptionDetails",
  ADD_NEW_PARTNER = "addNewPartner",
  ANALYZE_CSV = "analyzeCsv",
  GET_LEADS_LIST = "getLeadsList",
  GET_LEADS_LIST_BY_PARTNER = "getLeadsListByPartner",
  GET_PARTNERS_LIST = "getPartnersList",
  DELETE_PARTNER = "deletePartner",
  COURSE_BUY_BY_USER = "courseBuyByUser",
  SUBSCRIPTION_PUT_PAUSE = 'subscriptionPutPause',
  SUBSCRIPTION_PUT_PAUSE_OFF = 'subscriptionPutPauseOff',
  SUBSCRIPTION_UNSUBSCRIBE = 'subscriptionUnsubscribe',
  CHANGE_COURSE_AVAILABLE = 'courseChangeAvailable',
  GET_TELEGRAM_USERS = 'getTelegramUsers',
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_ROLE = "setRole",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_PARENT_TABLE = "setParentTable",
  SET_SUBSCRIBERS_TABLE = "setSubscribersTable",
  SET_SUBSCRIBERS_TODAY = "setSubscribersToday",
  SET_SUBSCRIBERS_DEBTORS = "setSubscribersDebtors",
  SET_USERS = "setUsers",
  SET_PARENT_TABLE_ERROR = "setParentTableError",
  SET_SUBSCRIBERS_ONE = "setSubscribersOne",
  SET_USER_STORE = "setUserStore",
  REMOVE_SUBSCRIBER = "removeSubscriber",
  SET_DATA_SUB = "setDataSub",
  SET_DATA_SUB_OBJ = "setDataSubObj",
  CLEAR_DATA_SUB_MUT = "clearDataSubMut",
  SET_SETTING_ERROR = "setSettingError",
  SAVE_SETTINGS = "saveSettings",
  SET_AUTH_SUB = "setAuthSub",
  SET_SUBSCRIBER = "setSubscriber",
  SET_ALL_CURSES = "setAllCurses",
  SET_ONE_COURSE = "setOneCourse",
  SAVE_PAYMENT_URL = "savePaymentUrl",
  SET_SUBSCRIBER_ARREARS = "setSubscriberArrears",
  SET_SUBSCRIBER_ERROR = "setSubscriberError",
  SET_SUM_AMOUNT_MUT = "setSumAmountMut",
  SET_ACCESS_TOKEN = "setAccessToken",
  SAVE_USER_SETTINGS = "saveUserSettings",
  SET_LEADS_LIST = "setLeadsList",
  SET_PARTNERS_LIST = "setPartnersList",
  SET_ACTUAL_USER_DATA = "setActualUserData",
}

export { Actions, Mutations };
