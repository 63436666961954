import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import config from "@/core/config/APIConfig";
import JwtService from "@/core/services/JwtService";

@Module
export default class SettingsModule extends VuexModule {
  userSettings = {}
  settingsData = {}

  get settingsError(): string | null {
    let settingError = localStorage.getItem("settingsError");
    if (settingError) {
      settingError = JSON.parse(settingError);
      return settingError;
    }
    return "";
  }

  get getterSettings(): any {
    return this.settingsData ? this.settingsData : {}
  }

  get getUserSettings(): any {
    return this.userSettings ? this.userSettings : {}
  }

  @Mutation
  [Mutations.SET_SETTING_ERROR](error) {
    localStorage.setItem("settingsError", JSON.stringify(error));
  }

  @Mutation
  [Mutations.SAVE_SETTINGS](settings) {
    this.settingsData = settings
  }

  @Mutation
  [Mutations.SAVE_USER_SETTINGS](settings) {
    console.log('SAVE_USER_SETTINGS ', settings)
    settings ? this.userSettings = settings : this.userSettings = {}
  }

  @Action
  [Actions.CLEAR_SETTING_ERROR]() {
    this.context.commit(Mutations.SET_SETTING_ERROR, "");
  }

  /**
   * Set common settings for manager and admin role
   * @params payload
   * @returns
   */
  @Action
  [Actions.SET_SETTINGS](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
        ApiService.post(config.routeSettingsSetSettings, { ...payload })
          .then((res) => {
            this.context.commit(Mutations.SAVE_SETTINGS, res.data.settings);
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(
              Mutations.SET_SETTING_ERROR,
              response.data.message
            );
            reject();
          });
      });
    }
  }

  /**
   * Set new courses information
   * @param payload
   * @returns 
   */
  @Action
  [Actions.SET_COURSES](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
        ApiService.put(config.routeCursesBase + "/" + payload._id, { ...payload })
          .then((res) => {
            console.log('SET_COURSES OK', res);
            resolve();
          })
          .catch(({ response }) => {
            console.log('SET_COURSES ERROR', response);
            reject();
          });
      });
    }
  }

  /**
   * Get common settings for manager and admin role
   * @returns
   */
  @Action
  [Actions.GET_SETTINGS]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
        ApiService.post(config.routeSettingsGetSettings, {})
          .then((res) => {
            this.context.commit(Mutations.SAVE_SETTINGS, res.data)
            resolve();
          })
          .catch(({ response }) => {
            console.log("GET_SETTINGS ERROR:", response.message);
            reject();
          });
      });
    }
  }

  /**
   * User Set Settings
   * @param payload
   * @returns
   */
  @Action
  [Actions.USER_SET_SETTINGS](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
        ApiService.put(config.routeUserSaveSettings, { ...payload })
            .then((res) => {
              this.context.commit(Mutations.SAVE_USER_SETTINGS, payload.settings);
              resolve();
            })
            .catch(({ response }) => {
              this.context.commit(
                  Mutations.SET_SETTING_ERROR,
                  response.data.message
              );
              reject();
            });
      });
    }
  }

  /**
   * User Get Settings
   * @returns
   */
  @Action
  [Actions.USER_GET_SETTINGS]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
        ApiService.get(config.routeUserLoadSettings)
            .then((res) => {
              this.context.commit(Mutations.SAVE_USER_SETTINGS, res.data);
              resolve();
            })
            .catch(({ response }) => {
              console.log("error:", response.message);
              reject();
            });
      });
    }
  }

  /**
   * Save user details
   * @returns
   */
  @Action
  [Actions.EDIT_USER_DETAILS](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
        ApiService.put(config.routeUserDetailsUpdate + payload['_id'], payload)
            .then((res) => {
              resolve();
            })
            .catch(({ response }) => {
              console.log('EDIT_USER_DETAILS ERROR - ', response)
              reject();
            });
      });
    }
  }

  /**
   * Change course available option
   * @params credentials
   * @returns
   */
  @Action
  [Actions.CHANGE_COURSE_AVAILABLE](credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
        ApiService.put(config.routeCourseChangeAvailability + credentials, {})
            .then((res) => {
              console.log('CHANGE_COURSE_AVAILABLE SUCCESS - ', res)
              resolve();
            })
            .catch(({ response }) => {
              console.log('CHANGE_COURSE_AVAILABLE ERROR - ', response)
              reject();
            });
      });
    }
  }

}
