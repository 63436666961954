const routeRoot: string = process.env.VUE_APP_BACKEND_API_BASE + process.env.VUE_APP_BACKEND_API_VERSION;

const config = {
  yookassaSearch: "https://yookassa.ru/my/payments?search=",
  routeRefreshToken: routeRoot + "/auth/refresh",
  routeLogout: routeRoot + "/auth/logout",
  routeSignin: routeRoot + "/auth/manager/signin",
  routeSignup: routeRoot + "/auth/registration",
  routeSubscriberSignIn: routeRoot + "/auth/subscriber/signin",
  routeGetUsers: routeRoot + "/subscriber/get-all",
  routeGetUser: routeRoot + "/subscriber/get-subscriber",
  routeSubscriptionGetAll: routeRoot + "/subscription/get-all",
  routeSubscriptionGetOne: routeRoot + "/subscription/get-one",
  routeSettingsSetSettings: routeRoot + "/settings/set-settings",
  routeSettingsGetSettings: routeRoot + "/settings/get-settings",
  routeSubscriberGet: routeRoot + "/subscriber/get-subscriber",
  routeCursesGetAll: routeRoot + "/courses/get-all",
  routeCursesBase: routeRoot + "/courses/",
  routeAuthSubscriberReg: routeRoot + "/auth/subscriber/reg",
  routeSubscriptionExpiringToday: routeRoot + "/subscription/get-expiring-subscriptions",
  routeSubscriptionDebtors: routeRoot + "/subscription/get-debtor-subscriptions",
  routeUserSaveSettings: routeRoot + "/users/set-settings",
  routeUserLoadSettings: routeRoot + "/users/get-settings",
  routeUserDetailsUpdate: routeRoot + "/users/user-update/",
  routeSubscriptionDetailsUpdate: routeRoot + "/subscription/edit-one/",
  routeAddNewPartner: routeRoot + "/partners/add-partner",
  routeAnalyzeCSV: routeRoot + "/partners/analyze-leeds",
  routeGetLeadsList: routeRoot + "/partners/leads/",
  routeGetLeadsListByPartner: routeRoot + "/partners/get-leads",
  routeGetPartnersList: routeRoot + "/partners/all",
  routeDeletePartnerByID: routeRoot + "/partners/",
  routeBuyOneCourse: routeRoot + "/subscriber/add-subscription",
  routeSubscriptionPutToPause: routeRoot + "/subscription/locked",
  routeSubscriptionPutToPauseOff: routeRoot + "/subscription/unlocked",
  routeSubscriptionUnsubscribe: routeRoot + "/subscription/unsubscribe",
  routeForgotPassword: routeRoot + "/auth/reset-password",
  routeCourseChangeAvailability: routeRoot + "/courses/available-switch/",
  routeTelegramUsers: routeRoot + '/dictionary/users',
};

export default config;
