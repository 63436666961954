import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import config from "@/core/config/APIConfig";
import store from "@/store";
import router from "@/router";

export interface User {
  user: {
    orders: [];
    subscriptions: [];
    _id: string;
    email: string;
    fname: string;
    lname: string;
    phone: number;
    role: string;
    createdAt: string;
    updatedAt: string;
  };
  accessToken: string;
  refreshToken: string;
  password?: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User | null | string {
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      return user;
    }
    return null;
  }

  get getPaymentUrl(): any {
    let savePaymentUrl = localStorage.getItem("savePaymentUrl");
    if (savePaymentUrl) {
      savePaymentUrl = JSON.parse(savePaymentUrl);
      return savePaymentUrl;
    }
    return null;
  }

  get isUserRole(): any {
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      return user.user.role ? user.user.role : "";
    }
    return "";
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  get getSubAuthError() {
    let error = localStorage.getItem("sub_error");
    if (error) {
      error = JSON.parse(error);
      return error;
    }
    return "";
  }

  get getDataSubStep1() {
    const data = localStorage.getItem("dataSubStep1");
    if (null !== data && data.length > 0) {
      return JSON.parse(<string>data);
    } else {
      return {};
    }
  }

  get getDataSubStep2() {
    const data = localStorage.getItem("dataSubStep2");
    if (null !== data && data.length > 0) {
      return JSON.parse(data);
    } else {
      return {};
    }
  }

  get getUserRole() {
    const data = localStorage.getItem("userRole");
    if (null !== data && data.length > 0) {
      return data;
    } else {
      return {};
    }
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    localStorage.setItem("user", JSON.stringify(user));
    this.errors = [];
    JwtService.saveToken(user.accessToken);
    JwtService.saveRefreshToken(user.refreshToken);
  }

  @Mutation
  [Mutations.SET_ROLE](role){
    localStorage.setItem("userRole", role);
  }

  @Mutation
  [Mutations.SET_ACCESS_TOKEN](token) {
    JwtService.saveToken(token);
  }

  @Mutation
  [Mutations.SET_AUTH_SUB](user) {
    this.isAuthenticated = true;
    const newUser = {
      user: {
        orders: user.subscriber.orders,
        subscriptions: user.subscriber.subscriptions,
        _id: user.subscriber._id,
        email: user.subscriber.email,
        fname: user.subscriber.fname,
        lname: user.subscriber.lname,
        phone: user.subscriber.phone,
        role: user.subscriber.role,
        createdAt: user.subscriber.createdAt,
        updatedAt: user.subscriber.updatedAt,
      },
      accessToken: user.accessToken,
      refreshToken: user.refreshToken,
      password: "",
    };
    localStorage.setItem("user", JSON.stringify(newUser));
    this.errors = [];
    JwtService.saveToken(newUser.accessToken);
    JwtService.saveRefreshToken(newUser.refreshToken);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  // @Mutation
  // [Mutations.SET_PASSWORD](password) {
  //   this.user.password = password;
  // }

  @Mutation
  [Mutations.SET_DATA_SUB](data) {
    localStorage.setItem("dataSubStep1", JSON.stringify(data));
  }

  @Mutation
  [Mutations.SET_DATA_SUB_OBJ](obj) {
    localStorage.setItem("dataSubStep2", JSON.stringify(obj));
  }

  @Mutation
  [Mutations.SAVE_PAYMENT_URL](savePaymentUrl) {
    localStorage.setItem("savePaymentUrl", JSON.stringify(savePaymentUrl));
  }

  @Mutation
  [Mutations.CLEAR_DATA_SUB_MUT]() {
    localStorage.removeItem("dataSubStep2");
    localStorage.removeItem("dataSubStep1");
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    localStorage.removeItem("user");
    localStorage.removeItem("subscriberInfo");
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.SET_SUBSCRIBER_ERROR](error) {
    localStorage.setItem("sub_error", JSON.stringify(error));
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(config.routeSignin, credentials)
          .then((res) => {
            console.log('LOGIN - ', res.data)
            this.context.commit(Mutations.SET_AUTH, res.data);
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, [response.data.message]);
            reject();
          });
    });
  }

  @Action
  [Actions.LOGIN_SUB](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(config.routeSubscriberSignIn, credentials)
          .then((res) => {

            this.context.commit(Mutations.SET_AUTH_SUB, res.data);
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, [response.data.message]);
            reject();
          });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.SET_ROLE, store.getters.isUserRole)
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.CLEAR_DATA_SUB]() {
    this.context.commit(Mutations.CLEAR_DATA_SUB_MUT);
  }

  @Action
  [Actions.SAVE_DATA_SUB](credentials) {
    this.context.commit(Mutations.SET_DATA_SUB, credentials);
  }

  @Action
  [Actions.SAVE_DATA_SUB_OBJ](credentials) {
    this.context.commit(Mutations.SET_DATA_SUB_OBJ, credentials);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(config.routeSignup, credentials)
          .then(() => {
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, [response.data.message]);
            reject();
          });
    });
  }

  @Action
  [Actions.REGISTER_SUB](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(config.routeSignup, credentials)
          .then((res) => {
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, [response.data.message]);
            reject();
          });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(config.routeForgotPassword, payload)
          .then(({ data }) => {
            //this.context.commit(Mutations.SET_AUTH, data);
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD_SUB](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(config.routeForgotPassword, payload)
          .then(({ data }) => {
            //this.context.commit(Mutations.SET_AUTH, data);
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
      if (JwtService.getToken()) {
        (<any>ApiService)
            .post(config.routeRefreshToken, {
              refreshToken: JwtService.getRefreshToken(),
            })
            .then(({data}) => {
              console.log("new access token", "..." + data.accessToken.slice(-6));
              this.context.commit(Mutations.SET_ACCESS_TOKEN, data.accessToken);
            })
            .catch(({response}) => {
              if (response.status === 401) {
                console.log("VERIFY_AUTH status = 401 => logout");
                store.dispatch(Actions.AUTH_LOGOUT)
                    .then((response) => {
                      if (response) {
                        console.log('AUTH_LOGOUT response - ', response)

                        this.context.commit(Mutations.SET_ROLE, response);

                        if (
                            ['manager', 'administrator', 'partner'].includes(response)
                        ) {
                          console.log('sign-in')
                          router.push({path: "/sign-in"});
                        } else {
                          console.log('login')
                          router.push({path: "/login"});
                        }
                      }
                    })
              }
            });
      } else {
        console.log('Mutations.PURGE_AUTH')
        this.context.commit(Mutations.PURGE_AUTH);
      }
  }

  @Action
  [Actions.AUTH_LOGOUT]() {
    return new Promise<void>((resolve, reject) => {
      (<any>ApiService)
          .post(config.routeLogout, {
            refreshToken: JwtService.getRefreshToken(),
          })
          .then(({ data }) => {
            const role = store.getters.isUserRole
            this.context.commit(Mutations.PURGE_AUTH);
            resolve(role);
          })
          .catch(({ response }) => {
            console.error("AUTH_LOGOUT - ERROR", response);
            reject();
          });
    });
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
          .then(({ data }) => {
            this.context.commit(Mutations.SET_USER, data);
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            reject();
          });
    });
  }

  @Action
  [Actions.AUTH_SUB_REG](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(config.routeAuthSubscriberReg, payload)
          .then(({ data }) => {
            this.context.commit(Mutations.SAVE_PAYMENT_URL, data.paymentURL);
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(
                Mutations.SET_SUBSCRIBER_ERROR,
                response.data.message
            );
            reject();
          });
    });
  }


  @Action
  [Actions.COURSE_BUY_BY_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(config.routeBuyOneCourse, payload)
          .then(({ data }) => {
            this.context.commit(Mutations.SAVE_PAYMENT_URL, data.paymentURL);
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(
                Mutations.SET_SUBSCRIBER_ERROR,
                response.data.message
            );
            reject();
          });
    });
  }

  @Action
  [Actions.GET_TELEGRAM_USERS]() {
    ApiService.setHeaderCustom('oHGD6deE6Um42pHiE2rXE2cpZtnL');
    return new Promise<void>((resolve, reject) => {
      ApiService.get(config.routeTelegramUsers)
          .then(({ data }) => {
            // this.context.commit(Mutations.SAVE_PAYMENT_URL, data.paymentURL);
            console.log("GET_TELEGRAM_USERS - OK");
            resolve(data);
          })
          .catch(({ response }) => {
            console.error("GET_TELEGRAM_USERS - ERROR", response);
            reject();
          });
    });
  }


}