import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import config from "@/core/config/APIConfig";
import JwtService from "@/core/services/JwtService";
import { SubscriptionSchema, SubscriberSchema } from "@/store/interfaces/StoreInterface";


@Module
export default class SubscribersModule extends VuexModule {
  subscriptionDetails = {};
  subscribersTableData = [];
  subscriberInfoData = {} as SubscriberSchema;
  sumAmount = 0;
  SubscriptionsToday = [] as Array<SubscriptionSchema>;
  SubscriptionsDebtors = [] as Array<SubscriptionSchema>;
  SubscriberInfoUser = {} as SubscriberSchema;

  get getSubscribersTableData() {
    return this.subscribersTableData ? this.subscribersTableData : []
  }

  /**
   * It not using in current version
   */
  get subscribersTable() {
    const data = localStorage.getItem("subscribersTable");
    if (null !== data && data.length > 0) {
      return JSON.parse(<string>data);
    } else {
      return [];
    }
  }

  get subscribersToday(): Array<SubscriptionSchema> {
    return this.SubscriptionsToday ? this.SubscriptionsToday : [];
  }

  get subscribersDebtors(): Array<SubscriptionSchema> {
    return this.SubscriptionsDebtors ? this.SubscriptionsDebtors : [];
  }

  get subscribersOne(): any {
    return this.subscriptionDetails ? this.subscriptionDetails : {}
  }

  get subscriberInfo(): SubscriberSchema {
    return this.SubscriberInfoUser ? this.SubscriberInfoUser : {};
  }

  get getSumAmount() {
    return this.sumAmount ? this.sumAmount : 0;
  }

  get getActualSubscriberInfo() {
    return this.subscriberInfoData ? this.subscriberInfoData : {}
  }

  @Mutation
  [Mutations.SET_SUBSCRIBERS_TABLE](table) {
    this.subscribersTableData = table
  }

  @Mutation
  [Mutations.SET_SUBSCRIBERS_TODAY](table) {
    this.SubscriptionsToday = table;
  }

  @Mutation
  [Mutations.SET_SUBSCRIBERS_DEBTORS](table) {
    this.SubscriptionsDebtors = table;
  }

  @Mutation
  [Mutations.SET_SUM_AMOUNT_MUT](value) {
    this.sumAmount = value;
  }

  @Mutation
  [Mutations.SET_SUBSCRIBERS_ONE](data) {
    this.subscriptionDetails = data
  }

  @Mutation
  [Mutations.SET_SUBSCRIBER](subscriber) {
    this.SubscriberInfoUser = subscriber;
  }

  /**
   * It not using in current version
   */
  @Mutation
  [Mutations.SET_SUBSCRIBER_ARREARS](table) {
    localStorage.setItem("setSubscriberArrears", JSON.stringify(table));
  }

  @Action
  [Actions.GET_SUBSCRIBERS_TABLE]() {
    if (JwtService.getToken()) {
      return new Promise<void>((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(config.routeSubscriptionGetAll, {})
          .then((res) => {
            this.context.commit(Mutations.SET_SUBSCRIBERS_TABLE, res.data);
            resolve();
          })
          .catch(({ response }) => {
            console.log("catch: ", response.data.message);
            reject();
          });
      });
    }
  }

  @Action
  [Actions.GET_SUBSCRIBERS_TODAY]() {
    if (JwtService.getToken()) {
      return new Promise<void>((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(config.routeSubscriptionExpiringToday)
          .then((res) => {
            this.context.commit(Mutations.SET_SUBSCRIBERS_TODAY, res.data);
            resolve();
          })
          .catch(({ response }) => {
            console.log("catch: ", response.data.message);
            reject();
          });
      });
    }
  }


  @Action
  [Actions.GET_SUBSCRIBERS_DEBTORS]() {
    if (JwtService.getToken()) {
      return new Promise<void>((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(config.routeSubscriptionDebtors)
          .then((res) => {
            this.context.commit(Mutations.SET_SUBSCRIBERS_DEBTORS, res.data);
            resolve();
          })
          .catch(({ response }) => {
            console.log("catch: ", response.data.message);
            reject();
          });
      });
    }
  }

  @Action
  [Actions.GET_SUBSCRIBERS_ONE](params) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(config.routeSubscriptionGetOne, params)
        .then((res) => {
          this.context.commit(Mutations.SET_SUBSCRIBERS_ONE, res.data);
          resolve();
        })
        .catch(({ response }) => {
          console.log("catch: ", response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_SUBSCRIBER](params) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(config.routeSubscriberGet, params)
        .then((res) => {
          this.context.commit(Mutations.SET_SUBSCRIBER, res.data);
          resolve();
        })
        .catch(({ response }) => {
          console.log("catch: ", response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_SUBSCRIBER_ARREARS]() {
    ApiService.setHeader();
    ApiService.post("/getSubscriberArrears", {})
      .then((res) => {
        this.context.commit(Mutations.SET_SUBSCRIBER_ARREARS, res.data);
      })
      .catch(({ response }) => {
        console.log("catch: ", response.data.message);
      });
  }

  @Action
  [Actions.SET_SUM_AMOUNT_ACT](value) {
    this.context.commit(Mutations.SET_SUM_AMOUNT_MUT, value);
  }

  //  Edit subscription details
  @Action
  [Actions.EDIT_SUBSCRIPTION_DETAILS](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
        ApiService.put(config.routeSubscriptionDetailsUpdate + payload['_id'], payload)
            .then((res) => {
              resolve();
            })
            .catch(({ response }) => {
              console.log('EDIT_SUBSCRIPTION_DETAILS ERROR - ', response)
              reject();
            });
      });
    }
  }

  /**
   * Put user subscription to pause
   * @param payload
   * @returns
   */
  @Action
  [Actions.SUBSCRIPTION_PUT_PAUSE](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
        ApiService.put(config.routeSubscriptionPutToPause, payload)
            .then((res) => {
              resolve();
            })
            .catch(({ response }) => {
              console.log('SUBSCRIPTION_PUT_PAUSE ERROR - ', response)
              reject();
            });
      });
    }
  }

  /**
   * Put user subscription to active if status is locked
   * @param payload
   * @returns
   */
   @Action
   [Actions.SUBSCRIPTION_PUT_PAUSE_OFF](payload) {
     if (JwtService.getToken()) {
       ApiService.setHeader();
       return new Promise<void>((resolve, reject) => {
         ApiService.put(config.routeSubscriptionPutToPauseOff, payload)
             .then((res) => {
               resolve();
             })
             .catch(({ response }) => {
               console.log('SUBSCRIPTION_PUT_PAUSE_OFF ERROR - ', response)
               reject();
             });
       });
     }
   }

   /**
   * Unsubscribe for this subscription
   * @param payload
   * @returns
   */
    @Action
    [Actions.SUBSCRIPTION_UNSUBSCRIBE](payload) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        return new Promise<void>((resolve, reject) => {
          ApiService.put(config.routeSubscriptionUnsubscribe, payload)
              .then((res) => {
                resolve();
              })
              .catch(({ response }) => {
                console.log('SUBSCRIPTION_UNSUBSCRIBE ERROR - ', response)
                reject();
              });
        });
      }
    }

}
