import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { permission: "manager" },
        component: () => import("@/views/dashboard/Dashboard.vue"),
      },

      //  Subscriber mode
      {
        path: "/account",
        name: "account",
        meta: { permission: "subscriber" },
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            meta: { permission: "subscriber" },
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            meta: { permission: "subscriber" },
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },

      //  Partner mode
      {
        path: "/partner",
        name: "partner",
        meta: { permission: "partner" },
        component: () => import("@/views/crafted/partner/PartnerLeads.vue"),
       },
      {
        path: "/new-subscriber",
        name: "newSubscriber",
        component: () =>
            import(
                "@/views/crafted/authentication/basic-flow-subscriber/newSubscriber.vue"
                ),
      },
      {
        path: "/subscriptions",
        name: "subscriptions",
        meta: { permission: "manager" },
        component: () =>
            import("@/views/dashboard/Subscriptions/Subscriptions.vue"),
        children: [
          {
            path: "subscription/:id",
            name: "subscription",
            meta: { permission: "manager" },
            component: () =>
                import("@/views/dashboard/Subscriptions/SubscribersOne.vue"),
          },
        ],
      },
      {
        path: "/users",
        name: "users",
        meta: { permission: "manager" },
        component: () => import("@/views/dashboard/Subscribers.vue"),
        children: [
          {
            path: "user/:id",
            name: "user",
            meta: { permission: "manager" },
            component: () => import("@/views/apps/customers/UsersDetails.vue"),
            beforeEnter: (to, from, next) => {
              store.dispatch(Actions.FETCH_USER, { id: to.params.id })
                  .then(() => next())
            }
          },
        ],
      },
      {
        path: "/dicionario",
        name: "dicionario",
        meta: { permission: "manager" },
        component: () => import("@/views/dashboard/Dicionario.vue"),
      },
      {
        path: "/partners",
        name: "partners",
        meta: { permission: "manager" },
        component: () => import("@/views/dashboard/Leads.vue"),
        children: [
          {
            path: "updatestatistic",
            name: "updatestatistic",
            meta: { permission: "manager" },
            component: () =>
                import("@/views/dashboard/leads/UpdateStatistic.vue"),
          },
        ],
      },
      {
        path: "/leads",
        name: "leads",
        meta: { permission: "manager" },
        component: () => import("@/views/dashboard/leads/Leads.vue"),
        // children: [
        //   {
        //     path: "updatestatistic",
        //     name: "updatestatistic",
        //     meta: { permission: "partner" },
        //     component: () =>
        //         import("@/views/dashboard/leads/UpdateStatistic.vue"),
        //   },
        // ],
      },
      {
        path: "/settings",
        name: "settings",
        meta: { permission: "manager" },
        component: () => import("@/views/dashboard/Settings.vue"),
      },
      {
        path: "/logs",
        name: "logs",
        meta: { permission: "manager" },
        component: () => import("@/views/dashboard/Logs.vue"),
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
                import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
                import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
                import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
                import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
                import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
                import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      },
      {
        path: "/account",
        name: "account",
        meta: { permission: "subscriber" },
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            meta: { permission: "subscriber" },
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            meta: { permission: "subscriber" },
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
          {
            path: "buy",
            name: "account-buy",
            meta: { permission: "subscriber" },
            component: () => import("@/views/crafted/account/Buy.vue"),
          },
          {
            path: "subscriptions",
            name: "account-subscriptions",
            meta: { permission: "subscriber" },
            component: () => import("@/views/crafted/account/Subscriptions.vue"),
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        /*component: () => import("@/views/apps/customers/CustomerDetails.vue")*/
        component: () =>
            import("@/views/apps/customers/SubscriptionDetails.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
            import("@/views/crafted/modals/general/InviteFriends.vue"),
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
            import("@/views/crafted/modals/general/UpgradePlan.vue"),
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
            import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
            import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
            import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
            import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        component: () =>
            import(
                "@/views/crafted/authentication/basic-flow-subscriber/loginSubsciber.vue"
                ),
      },
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
            import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/new-subscriber",
        name: "newSubscriber",
        component: () =>
            import(
                "@/views/crafted/authentication/basic-flow-subscriber/newSubscriber.vue"
                ),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
            import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
            import(
                "@/views/crafted/authentication/basic-flow-subscriber/PasswordResetSub.vue"
                ),
      },
      {
        path: "/password-reset-admin",
        name: "password-reset-admin",
        component: () =>
            import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log('before each')
  // console.log('to - ', to, 'from - ', from, 'next - ', next)
  // console.log('router.beforeEach ', router)

  if ( to.name === 'login' || to.name === 'sign-in' ) {
    next()
    return
  }

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH)
      .then((response) => {
        console.log('VERIFY_AUTH - response ', response)
        if (response) return

        if (store.getters.isUserRole) {
          console.log('checking user role - ', store.getters.isUserRole)
          if ( ['manager', 'administrator'].includes(store.getters.isUserRole)) {
            console.log('manager or admin')
            next();
          } else {
            console.log('NOT manager or admin or partner')
            if (store.getters.isUserRole === to.meta.permission) {
              console.log('store.getters.isUserRole - ', store.getters.isUserRole)
              console.log('to.meta.permission - ', to.meta.permission)
              next();
            } else {
              if (store.getters.isUserRole === 'partner') {
                next({ name: "partner" });
              } else {
                // Base redirect for subscriber
                next({ name: "account" });
              }

            }
          }
        } else {
          console.log('NOt find userRole in localstorage', store.getters.isUserRole)
          console.log('to.name - ', to.name)
          if (
              // Exception routes
              to.name === "sign-up" ||
              to.name === "sign-in" ||
              to.name === "newSubscriber" ||
              to.name === "password-reset-admin" ||
              to.name === "password-reset"
          ) {
            console.log('to.name -  1')
            next();
            console.log(to.name)
          } else {
            console.log('to.name -  2')
            // The others routes redirect to login
            if (to.name !== "login") {
              next({ name: "login" });
            } else {
              next();
            }
          }
        }

        // Scroll page to top on every route change
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      })

    /*if (JwtService.getRefreshToken()) {
      store.dispatch(Actions.VERIFY_AUTH, {refreshToken: JwtService.getRefreshToken()});
    } else {
      store.dispatch(Actions.LOGOUT);
    }*/
});

export default router;
